<script setup>
import { ref, onMounted } from "vue";
const image1 = ref(require("@/assets/inf/graphs/Real2.png"));
const image2 = ref(require("@/assets/inf/graphs/Matrix2.png"));
const image3 = ref(require("@/assets/inf/graphs/Graph2.png"));

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Reales Leben vs. Matrix vs. Graphen</h1>

      <p>
        Stellen Sie sich vor, dass Tom nach dem Ende seiner Unterrichtsstunde
        beschließt, in einen Laden zu gehen. Die Darstellung dieser Route würde
        wie folgt aussehen:
      </p>

      <div class="images-container animate glow delay-4">
        <div class="image-item">
          <img :src="image1" alt="Real1" class="image1" />
          <div class="image-text">
            Reale Darstellung der Route von der Universität zum Laden
          </div>
        </div>
        <div class="image-item">
          <img :src="image2" alt="Matrix1" class="image2" />
          <div class="image-text">Darstellung als eine Adjazenzmatrix</div>
        </div>
        <div class="image-item">
          <img :src="image3" alt="Graph1" class="image3" />
          <div class="image-text">Darstellung als ein Graph</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.images-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image1 {
  height: 300px;
}
.image2 {
  height: 240px;
  margin-bottom: 60px;
}
.image3 {
  height: 240px;
  margin-bottom: 60px;
}

.image-item:nth-child(1) .image {
  height: 300px;
}

.image-text {
  text-align: center;
  font-size: small;
}
</style>
