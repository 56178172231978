<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-2">
      <h1>Speichern und Anzeigen von Graphen</h1>
      <p>
        Um Graphen am Computer erstellen zu können, müssen jedoch die Daten
        zuerst gespeichert werden. Dafür können verschiedene Speicherungsarten
        herangezogen werden, eine davon ist eine so genannte Adjazenzmatrix.
      </p>
      <h2>Was ist eine Adjazenmatrix?</h2>
      <p>
        Eine Adjazenzmatrix ist eine quadratische Matrix, die verwendet wird, um
        einen Graphen darzustellen. Die Elemente der Matrix zeigen an, ob die
        Knoten im Graphen miteinander verbunden sind oder nicht.
      </p>

      <p>Das Ganze ist dabei wie folgt aufgebaut:</p>
      <p>
        <strong>Knoten und Kanten:</strong> Betrachten Sie einen Graphen mit
        mehreren Knoten, die Städte, Personen oder Computer sein
        können. Diese Knoten sind durch Kanten verbunden, welche die Beziehung
        oder Verbindung zwischen den Knoten darstellen.
      </p>

      <p>
        <strong>Matrixerstellung:</strong> In einer Adjazenzmatrix repräsentiert
        jede Zeile und jede Spalte einen Knoten des Graphen. Wenn Sie n Knoten
        haben, wird Ihre Adjazenzmatrix die Größe <em>n×n</em> haben.
      </p>

      <div class="row">
        <div class="col">
          <p><strong>Matrixbefüllung:</strong></p>
          <ul>
            <li>
              Setzen Sie eine '1' in die Matrix an der Position <em>(i,j)</em>,
              wenn es eine Kante (eine direkte Verbindung) zwischen den beiden Knoten
              <em>i</em> und <em>j</em> gibt.
            </li>
            <li>
              Setzen Sie eine '0' in die Matrix, wenn es keine direkte
              Verbindung zwischen den beiden Knoten <em>i</em> und <em>j</em> gibt.
            </li>
            <li>
              Wenn der Graph ungerichtet ist (was bedeutet, dass Kanten in beide
              Richtungen gehen), wird die Matrix symmetrisch sein. Das heißt,
              wenn der Knoten <em>i</em> mit dem Knoten <em>j</em> verbunden ist, dann
              ist der Knoten <em>j</em> auch mit dem Knoten <em>i</em> verbunden, so
              dass sowohl <em>(i,j)</em> als auch <em>(j,i)</em> in der Matrix
              den Wert '1' haben werden.
            </li>
            <li>
              In einem gerichteten Graphen (das heißt Verbindungen besitzen eine bestimmte
              Richtung), muss die Matrix nicht symmetrisch sein. Eine '1' an der Stelle <em>(i,j)</em>, 
              und eine '0' an der Stelle <em>(j,i)</em> verdeutlicht, dass es sich um eine gerichtete
              Kante vom Knoten <em>i</em> zu Knoten <em>j</em> handelt.
            </li>
          </ul>
        </div>

        <div class="col">
          <p><strong>Beispiel einer Matrix mit vier Knoten:</strong></p>
          <p>Die Matrix besteht daher aus 4 Zeilen und 4 Spalten.</p>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>A</th>
                <td>0</td>
                <td><div class="td-highlight">1</div></td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <th>B</th>
                <td><div class="td-highlight">1</div></td>
                <td>0</td>
                <td>1</td>
                <td>0</td>
              </tr>
              <tr>
                <th>C</th>
                <td>0</td>
                <td>1</td>
                <td>0</td>
                <td>1</td>
              </tr>
              <tr>
                <th>D</th>
                <td>1</td>
                <td>0</td>
                <td>1</td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
          <p>
            Wenn wir nun beispielsweise die Knoten <em>A</em> und
            <em>B</em> durch eine Kante verbinden wollen, müssen wir in der
            Matrix an der Stelle <em>(i,j)</em> = <em>(A,B)</em> und an der
            Stelle <em>(j,i)</em> = <em>(B,A)</em> eine '1' eintragen.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

ul li {
  margin: 0 24px 24px;
  list-style-type: disc;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }

  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}

em {
  letter-spacing: 0.2em;
  font-family: "JetBrains Mono", monospace;
}

table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  min-width: 400px;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}
.td-highlight {
  background-color: #f0ffae;
}
</style>
