<script setup>
import { ref, nextTick, onMounted, defineEmits } from "vue";
const emit = defineEmits(["enableWeiter", "disableWeiter"]);

const nodes = ref([]);
const newNodeName = ref("");
const selectedNodes = ref([]);
const edges = ref([]);
const resultMessage = ref("");
const tutorialFinished = ref(false);

const handlePlusClick = () => {
  if (newNodeName.value.trim() !== "") {
    if (nodes.value.includes(newNodeName.value.trim())) {
      resultMessage.value =
        "Der Name ist bereits vergeben. Stellen Sie sicher, dass der Name eindeutig ist.";
    } else {
      nodes.value.push(newNodeName.value.trim()); // Add the new node to the nodes array
      newNodeName.value = "";
      resultMessage.value = ""; // Clear any previous result message
    }
  } else {
    resultMessage.value = "Bitte geben Sie einen Namen für den Knoten ein.";
  }
};

const handleNodeClick = (index) => {
  if (selectedNodes.value.includes(index)) {
    // click on already selected node --> remove it from selectedNodes
    selectedNodes.value = selectedNodes.value.filter((node) => node !== index);
    return;
  }
  if (!selectedNodes.value.includes(index)) {
    selectedNodes.value.push(index);
  }

  if (selectedNodes.value.length === 2) {
    const [node1, node2] = selectedNodes.value;
    // check if edge is already drawn
    let edgeExists = false;
    edges.value.forEach((edge) => {
      if (
        (edge.start == node1 && edge.end == node2) ||
        (edge.end == node1 && edge.start == node2)
      ) {
        resultMessage.value = "Diese Kante existiert bereits.";
        selectedNodes.value = [];
        edgeExists = true;
      }
    });

    if (!edgeExists) {
      edges.value.push({ start: node1, end: node2 });
      selectedNodes.value = [];
    }
  }
};

const getNodeStyle = (index) => {
  switch (index) {
    case 0:
      return { top: "20px", left: "50%", transform: "translateX(-50%)" }; // Center top for first node
    case 1:
      return { top: "200px", left: "calc(40% - 80px)" }; // Below first node, to the left for second node
    case 2:
      return { top: "200px", left: "calc(60% + 30px)" }; // Below first node, to the right for third node
    default:
      return {}; // Default case if more than 3 nodes somehow
  }
};

const getNodeClass = (index) => {
  return {
    node: true,
    "node-selected": selectedNodes.value.includes(index),
  };
};

const calculateEdgePosition = (node1, node2) => {
  const container = document.querySelector(".graph-canvas");
  const node1El = container.querySelectorAll(".node")[node1];
  const node2El = container.querySelectorAll(".node")[node2];

  if (!node1El || !node2El) return {};

  const rect1 = node1El.getBoundingClientRect();
  const rect2 = node2El.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Coordinates of node centers
  const x1 = rect1.left + rect1.width / 2 - containerRect.left;
  const y1 = rect1.top + rect1.height / 2 - containerRect.top;
  const x2 = rect2.left + rect2.width / 2 - containerRect.left;
  const y2 = rect2.top + rect2.height / 2 - containerRect.top;

  // Calculate distance and angle
  const dx = x2 - x1;
  const dy = y2 - y1;
  const angle = Math.atan2(dy, dx);

  // Node radius and edge buffer
  const nodeRadius = 25; // Half of the node diameter, nodes are 50px in diameter
  const edgeOffset = 5; // How much shorter each end of the line should be

  // Calculate start and end points to not overlap nodes
  const startOffset = nodeRadius + edgeOffset;
  const endOffset = nodeRadius + edgeOffset;
  const length = Math.sqrt(dx * dx + dy * dy) - startOffset - endOffset;

  // Adjusted start position
  const startX = x1 + startOffset * Math.cos(angle);
  const startY = y1 + startOffset * Math.sin(angle);

  return {
    width: `${length}px`,
    height: "2px", // Keeping your edge thickness
    top: `${startY}px`,
    left: `${startX}px`,
    transform: `rotate(${angle * (180 / Math.PI)}deg)`,
    transformOrigin: "0 0",
    position: "absolute",
    backgroundColor: "black", // Ensure the line color is set here or in CSS
  };
};

const handleSubmit = () => {
  if (nodes.value.length >= 3 && edges.value.length >= 3) {
    tutorialFinished.value = true;
    resultMessage.value =
      "Sie haben das Tutorial abgeschlossen. Drücken Sie den Button 'Weiter' um mit der Aufgabe zu beginnen. Viel Spaß!";
    emit("enableWeiter");
  } else {
    resultMessage.value =
      "Bitte erstellen Sie mindestens 3 Knoten und 3 Kanten";
  }
};

const resetForm = () => {
  nodes.value = [];
  edges.value = [];
  selectedNodes.value = [];
  resultMessage.value = "";
  tutorialFinished.value = false;
};

onMounted(() => {
  emit("disableWeiter");
  nextTick(() => {
    edges.value = edges.value.map((edge) =>
      calculateEdgePosition(edge[0], edge[1])
    );
  });
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Tutorial</h1>
      <p>
        In diesem Modul haben Sie bisher erfahren was Graphen sind und wofür Sie
        in der Informatik eingesetzt werden. Dazu haben Sie auch
        Adjazenzmatrizen kennen gelernt, eine Methode zum Speichern von Daten,
        die für die Erstellung von Graphen verwendet werden.
      </p>
      <p>
        Im ersten Übungsteil dieses Moduls werden Sie Daten aus einer vorgegeben
        Adjazenzmatrix verwenden um einen Graphen zu modellieren. Dazu müssen
        Sie alle Knoten und Kanten der Matrix auslesen und erstellen. Versuchen
        Sie, sich daran zu erinnern,
        <strong>wofür 0 und 1 in einer Adjazenzmatrix stehen.</strong>
        Bevor wir mit der Übung beginnen, können Sie sich auf dieser Seite mit
        der Plattform vertraut machen.
      </p>
      <p>
        Klicken Sie auf das <strong>Pluszeichen</strong>, nachdem Sie den
        <strong>Namen</strong> eingegeben haben, um einen
        <strong>Knoten</strong> zu erstellen.
      </p>
      <p>
        Um zwei Knoten mit einer <strong>Kante</strong> zu verbinden,
        <strong>klicken Sie nacheinander auf die zu verbindenen Knoten</strong>.
      </p>
      <p>
        Sollten Sie fertig sein, können Sie Ihren Graphen mit der Schaltfläche
        <strong>"Lösung überprüfen"</strong> kontrollieren.
      </p>
      <strong style="display: flex; justify-content: center; margin-top: 1.5rem"
        >Erstellen Sie drei Knoten und verbinden Sie diese mit drei Kanten bevor
        Sie mit der ersten Aufgabe beginnen.</strong
      >
    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 input-container">
        <div class="graph-canvas">
          <div
            v-for="(node, index) in nodes"
            :key="index"
            :class="getNodeClass(index)"
            :style="getNodeStyle(index)"
            @click="handleNodeClick(index)"
          >
            {{ node }}
          </div>
          <div
            v-for="(edge, index) in edges"
            :key="'edge-' + index"
            class="edge"
            :style="calculateEdgePosition(edge.start, edge.end)"
          ></div>
        </div>
        <input v-model="newNodeName" placeholder="Name des Knotens" />
        <div class="button-group">
          <button type="button" class="plus-button" @click="handlePlusClick">
            +
          </button>
          <button type="button" class="submit-button" @click="handleSubmit">
            Lösung überprüfen
          </button>
          <button type="button" class="repeat-button" @click="resetForm">
            Zurücksetzen
          </button>
        </div>
      </div>
    </div>

    <div class="additional-box">
      {{ resultMessage }}
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  text-align: left;
}

.row {
  margin-bottom: 20px;
  width: 100%;
}
.image-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  max-width: 100%;
  height: 250px;
  margin-top: 50px;
}

.graph-canvas {
  font-family: "Courier New", Courier, monospace;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
  width: 100%;
  height: 400px;
  position: relative;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.submit-button,
.repeat-button,
.next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button,
.repeat-button {
  background-color: #4caf50;
  color: white;
}

.submit-button:hover,
.repeat-button:hover {
  background-color: #45a049;
}

.plus-button {
  font-size: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #105490;
  color: white;
  height: 48px;
}

.plus-button:hover {
  background-color: #0d406d;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
}

.button-active {
  background-color: #4caf50;
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7;
  color: rgb(123, 117, 117);
  cursor: not-allowed;
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}

.node {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #105490;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
}

.node-selected {
  background-color: #ffa500; /* Highlight color */
}

.edge {
  position: absolute;
  height: 2px;
  background-color: black;
}

input[type="text"] {
  margin-top: 10px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 12px); /* Adjust width to fit within the container */
}

table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  min-width: 400px;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}
.td-highlight {
  background-color: #f0ffae;
}
.justify-content-center {
  display: flex;
  justify-content: center;
}
</style>
