<script setup>
import { ref, nextTick, onMounted, defineEmits } from "vue";
const emit = defineEmits(["enableWeiter", "disableWeiter"]);

const image1 = ref(require("@/assets/inf/graphs/tutorial2.png")); // Path to the uploaded image
const nodeSets = ref([]);
const resultMessage = ref("");
const nodeCount = ref(0);
const tutorialFinished = ref(false);

const handleInput = (setIndex, nodeIndex, event) => {
  nodeSets.value[setIndex][nodeIndex] = event.target.textContent.trim();
  event.target.innerText = nodeSets.value[setIndex][nodeIndex];
};

const getNodeStyle = (setIndex, nodeIndex) => {
  return {
    top: `${40 + setIndex * 60}px`, // Adjusted to position nodes in each set lower
    left: `${nodeIndex * 100 + 50}px`,
    transform: "translateY(-50%)",
  };
};

const getNodeClass = () => {
  return {
    node: true,
  };
};

const handlePlusClick = () => {
  if (nodeCount.value > 5 || nodeCount.value < 1) {
    resultMessage.value = "Die Anzahl der Knoten muss zwischen 1 und 5 liegen.";
    return;
  } else if (nodeSets.value.length < 6) {
    const arr = Array.from(" ".repeat(nodeCount.value));
    nodeSets.value.push(arr);
  } else {
    resultMessage.value =
      "Die maximale Anzahl von Knotensätzen wurde erreicht.";
  }
};

const handleSubmit = () => {
  /*   const isCorrect = nodeSets.value.every(nodeSet => {
      const lowerCaseNodeSet = nodeSet.map(node => node.toLowerCase())
      return correctSolutions.some(solution => {
        return JSON.stringify([...lowerCaseNodeSet].sort()) === JSON.stringify([...solution].sort())
      })
    }) */

  if (nodeSets.value.length < 6) {
    let setComplete = true;
    nodeSets.value.forEach((set) => {
      for (let i = 0; i < set.length; i++) {
        if (!set[i] || set[i] == "" || set[i] == " ") {
          setComplete = false;
          break;
        }
      }
    });
    if (setComplete && nodeSets.value.length > 0) {
      tutorialFinished.value = true;
      resultMessage.value =
        "Sehr gut! Sie haben das Tutorial abgeschlossen und können mit der Aufgabe beginnen.";
      emit("enableWeiter");
      return;
    }
    resultMessage.value =
      "Vervollständigen Sie mindestens einen Zyklus und überprüfen Sie Ihre Lösung um das Tutorial zu beenden.";
  }
};

const resetForm = () => {
  nodeSets.value = [];
  resultMessage.value = "";
};

onMounted(() => {
  emit("disableWeiter");
  resultMessage.value =
    "Vervollständigen Sie mindestens einen Zyklus und überprüfen Sie Ihre Lösung um das Tutorial zu beenden.";
  nextTick(() => {
    // Any additional logic to run on mount
  });
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Tutorial: Aufgabe 2</h1>
      <p>
        Im zweiten Teil dieses Moduls ist es Ihre Aufgabe, mögliche Zyklen eines
        dargestellten Graphen zu erkennen. Erinnern Sie sich an die
        Voraussetzungen eines zyklischen Graphen und finden Sie alle Zyklen mit
        allen darin enthaltenen Knoten. Beschreiben Sie dazu mögliche Zyklen des
        Graphen als Sammlung von Knoten indem Sie Pfade erstellen und die
        enthaltenen Knotennamen in die Eingabefelder eintragen.
      </p>
      <h2>Beschreibung</h2>
      <ul>
        <li>
          Mit dem <strong>Plus Button</strong> können Sie einen neuen Pfad erstellen
          nachdem Sie die Länge, also die Anzahl der Knoten, im Textfeld
          angeben haben.
        </li>
        <li>
          Schreiben Sie die Namen der entsprechenden Knoten in die Felder
          des erstellten Pfades neben dem Graphen.
          <strong
            >Achtung: Jeder Knoten - auch der Start-/Endknoten - darf nur einmal
            vorkommen.</strong
          >
        </li>
        <li>
          Sollten Sie der Meinung sein, dass es mehrere Zyklen im Graphen gibt,
          können Sie weitere Pfade hinzuzufügen.
        </li>
        <li>
          Wenn Sie fertig sind, müssen Sie Ihre Änderungen mit der Schaltfläche
          <strong>"Lösung überprüfen"</strong> abgeben und kontrollieren.
        </li>
        <li>
          Mit der Schaltfläche <strong>"Zurücksetzen"</strong> können Sie mit
          der Aufgabe neu starten.
        </li>
      </ul>

      <p>
        <strong>In diesem Tutorial wird Ihre Lösung nicht überprüft!</strong>
        Beschreiben und überprüfen Sie zumindest einen Zyklus um mit der Aufgabe
        zu beginnen.
      </p>
    </div>

    <div class="content">
      <div class="image-item">
        <img :src="image1" alt="tutorial2" class="image1" />
      </div>

      <div class="input-container">
        <div class="sql-input">
          <div v-for="(nodeSet, setIndex) in nodeSets" :key="setIndex">
            <div
              v-for="(node, nodeIndex) in nodeSet"
              :key="nodeIndex"
              :class="getNodeClass()"
              :style="getNodeStyle(setIndex, nodeIndex)"
              @input="handleInput(setIndex, nodeIndex, $event)"
              contenteditable="true"
            >
              <div>{{ node }}</div>
            </div>
          </div>
        </div>
        <div class="button-group">
          <div class="add-button-group">
            <label for="node-count">Anzahl Knoten im Zyklus (max. 5)</label>
            <input
              class="form-control custom-input"
              id="node-count"
              type="number"
              min="1"
              max="5"
              v-model="nodeCount"
            />
            <button
              type="button"
              class="plus-button"
              @click="handlePlusClick"
              :disabled="nodeSets.length >= 6"
            >
              +
            </button>
          </div>
          <div class="submit-button-group">
            <button type="button" class="submit-button" @click="handleSubmit">
              Lösung überprüfen
            </button>
            <button type="button" class="repeat-button" @click="resetForm">
              Zurücksetzen
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="additional-box">
      {{ resultMessage }}
    </div>
  </div>
</template>

<style scoped>
.row {
  margin-bottom: 20px;
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.image-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  max-width: 95%;
  height: auto;
  margin-top: 50px;
}

.input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sql-input {
  font-family: "Courier New", Courier, monospace;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
  width: 100%;
  height: 400px;
  position: relative;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-button-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.submit-button-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.submit-button,
.repeat-button,
.next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button,
.repeat-button {
  background-color: #4caf50;
  color: white;
}

.submit-button:hover,
.repeat-button:hover {
  background-color: #45a049;
}

.plus-button {
  font-size: 2rem;
  width: 3rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #105490;
  color: white;
}

.plus-button:hover {
  background-color: #0d406d;
}

.plus-button:disabled {
  background-color: #e7e7e7;
  color: #ccc;
  cursor: not-allowed;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
}

.button-active {
  background-color: #4caf50;
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7;
  color: rgb(123, 117, 117);
  cursor: not-allowed;
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}

.node {
  width: 80px;
  /* increased width for better text visibility */
  height: 50px;
  /* increased height for better text visibility */
  border-radius: 10px;
  /* changed to rectangular shape for better text visibility */
  background-color: #105490;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  /* adjusted font size */
  position: absolute;
  cursor: pointer;
  user-select: text;
}

.node:focus {
  border: 0.2rem solid #ffffff;
  font-size: 20px;
  width: 90px;
  height: 60px;
}
ul{
  padding-left: 3rem
}
</style>
