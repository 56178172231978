<script setup>
import { ref, onMounted } from "vue";

const image1 = ref(require("@/assets/inf/graphs/detect_not_cycle.png"));
onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Erkennung eines Zyklus</h1>

      <p>
        Wenn wir nun aber die Kante, die die beiden Knoten <em>3</em> und
        <em>6</em> verbindet, entfernen, würde dieser Graph dann noch immer
        einen Zyklus enthalten? Die Antwort lautet: Nein, das würde er nicht.
      </p>
      <div class="images-container animate glow delay-4">
        <div class="image-item">
          <img :src="image1" alt="detect_not_cycle" class="image1" />
          <div class="image-text">Visualisierung eines azyklischen Graphen</div>
        </div>
      </div>

      <p>
        Warum ist das so? Wenn wir uns die Kanten erneut ansehen und dabei
        insbesondere die Knoten <em>3</em> und <em>6</em> betrachten, können wir
        erkennen, dass kein Pfad mehr existiert, um beim Knoten <em>3</em> zu
        starten und zu enden ohne einen Pfad mehrmals entlangzugehen. Dies
        erfüllt nicht die Voraussetzung eines Zyklus, in dem jede Kante einzigartig sein
        muss.
      </p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.image1 {
  height: 280px;
  width: auto;
}
.images-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.image-text {
  text-align: center;
  font-size: small;
}

em {
  letter-spacing: 0.2em;
  font-family: "JetBrains Mono", monospace;
}
</style>
