<script setup>
import { ref } from "vue";
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
const image1 = ref(require("@/assets/inf/graphs/Real1.png"));
const image2 = ref(require("@/assets/inf/graphs/Matrix1.png"));
const image3 = ref(require("@/assets/inf/graphs/Graph1.png"));
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-2">
      <h1>Reales Leben vs. Matrix vs. Graphen</h1>

      <p>
        Nehmen wir an, dass Tom zur Universität gehen möchte. Er nimmt die
        Route, die er normalerweise von seinem Haus zum Campus benutzt. Unten
        sehen Sie eine vereinfachte Darstellung, wie Toms Route als
        Adjazenzmatrix aussehen würde, und dann als Graph, wobei "Haus" und
        "Universität" Knoten sind und die Straße zur Universität als Kante
        betrachtet wird.
      </p>
      <div class="images-container animate glow delay-4">
        <div class="image-item">
          <img :src="image1" alt="Real1" class="image1" />
          <div class="image-text">
            Reale Darstellung der Route von zu Hause zur Universität
          </div>
        </div>
        <div class="image-item">
          <img :src="image2" alt="Matrix1" class="image2" />
          <div class="image-text">Darstellung als eine Adjazenzmatrix</div>
        </div>
        <div class="image-item">
          <img :src="image3" alt="Graph1" class="image3" />
          <div class="image-text">Darstellung als ein Graph</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.images-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image1 {
  height: 300px;
}
.image2 {
  height: 240px;
  margin-bottom: 60px;
}
.image3 {
  height: 240px;
  margin-bottom: 60px;
}

.image-item:nth-child(1) .image {
  height: 300px;
}

.image-text {
  text-align: center;
  font-size: small;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-2 {
  animation-delay: 0.7s;
}

.delay-5 {
  animation-delay: 1s;
}
</style>
