<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Appendix: Anwendung von Graphen</h1>
      <p>
        Graphen sind vielseitige Datenstrukturen, die in vielen verschiedenen Bereichen und Anwendungen
        verwendet werden:
      </p>
      <ul>
        <li><strong>Soziale Netzwerke: </strong>Graphen modellieren Netzwerke von Verbindungen zwischen Menschen, wobei
          Knoten Individuen und Kanten Verbindungen oder Interaktionen zwischen ihnen repräsentieren.</li>
        <li><strong>Computernetzwerke: </strong>Knoten stellen Computer oder Server dar und Kanten die
          Kommunikationswege.</li>
        <li><strong>Verkehrsnetzwerke: </strong>Knoten können Standorte (wie Städte, Straßenkreuzungen) sein und Kanten
          können Routen oder Flüge zwischen diesen Standorten darstellen.</li>
        <li><strong>Biologie: </strong>Graphen werden verwendet, um biologische Systeme wie neuronale Netzwerke,
          Protein-Interaktionsnetzwerke und Ökosysteme zu modellieren.</li>
        <li><strong>Webnavigation: </strong>Das Internet kann als riesiger Graph dargestellt werden, mit Webseiten als
          Knoten
          und Hyperlinks als Kanten.</li>
        <li><strong>Projektmanagement: </strong> Graphen können Projektpläne in Werkzeugen wie PERT (Program Evaluation
          and Review Technique) und CPM (Critical Path Method) darstellen, wobei Knoten Aufgaben und
          Kanten Abhängigkeiten zwischen diesen Aufgaben repräsentieren.</li>
        <li><strong>Operationsforschung und Planung: </strong>Graphen werden umfangreich in der Operationsforschung
          verwendet, um Probleme im Zusammenhang mit Logistik, Lieferketten und Optimierung zu lösen.</li>
      </ul>
      <p>Graphen dienen als grundlegende Werkzeuge in der Informatik und Mathematik und helfen dabei,
        verschiedene praktische Probleme durch visuelle Darstellung und algorithmische Analyse zu lösen.</p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }

  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}

.delay-4 {
  animation-delay: 1.3s;
}
</style>
