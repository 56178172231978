<script setup>
import { ref, onMounted } from "vue";

const image1 = ref(require("@/assets/inf/graphs/detect_cycle.png"));

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Erkennung eines Zyklus</h1>
      <p>
        Die Zyklenerkennung ist ein bedeutendes Forschungsgebiet in der
        Informatik. Es beschreibt die notwendige Vorgehensweise um Zyklen 
        eines Graphen zu finden und zu identifizieren. Dadurch können beispielsweise Fehler 
        in voneinander abhängigen Schritten innerhalb eines Systems erkannt werden.
      </p>
      <p>
        Im dargestellten Graphen bilden die Knoten <em>3-4-5-6-3</em>
        einen Zyklus:
      </p>

      <div class="images-container animate glow delay-4">
        <div class="image-item">
          <img :src="image1" alt="detect_cycle" class="image1" />
          <div class="image-text">
            Visualisierung eines Zyklus in einem Graphen
          </div>
        </div>
      </div>

      <p>
        Warum ist das so? Wenn Sie genau hinsehen und auf die Kanten achten,
        werden Sie feststellen, dass der Teilgraph <em>(3-4-5-6-3)</em> mit Knoten <em>3</em> endet und
        beginnt. Wir können bei Knoten <em>3</em> starten und die Konten <em>4, 5 und 6</em> besuchen. 
        Da aber ebenfalls eine Kante von Konten <em>3</em> und <em>6</em> existiert, bildet dieser Graph einen
        Zyklus, weil der Knoten <em>3</em> durch eine "neue" Kante erreicht werden kann.
      </p>
      <p>
        Dieser Pfad <em>(3-4-5-6-3)</em> erfüllt daher die Voraussetzungen für einen Zyklus und wir können sagen, dass der Graph zyklisch ist.
      </p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.image-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Image-text {
  text-align: center;

  font-size: small;
}
.image1 {
  height: 280px;
  width: auto;
}
.images-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.image-text {
  text-align: center;
  font-size: small;
}

em{
  letter-spacing: 0.2em;
  font-family: 'JetBrains Mono', monospace;
}
</style>
