<script setup>
import { ref, nextTick, onMounted, defineEmits } from 'vue';
import store from '@/store'

const emit = defineEmits(["enableWeiter", "disableWeiter"]);

const image1 = ref(require('@/assets/inf/graphs/graph_assign2.jpg')) // Path to the uploaded image
const nodeSets = ref([])
const resultMessage = ref('')
const nodeCountInput = ref(0);
const correctSolutions = [
  ['a', 'b', 'c', 'd', 'e'],
  ['a', 'b', 'e'],
  ['a', 'b', 'd', 'e'],
  ['b', 'c', 'd', 'e'],
  ['b', 'c', 'd'],
  ['b', 'd', 'e']
];

const totalWrongLengthErrors = ref(0);
const totalWrongNodesErrors = ref(0);
const totalTooManyCyclesErrors = ref(0);
const totalMissingCyclesErrors = ref(0);
const totalMissingNodeNameErrors = ref(0);

const consecutiveErrors = ref(0);

const assignmentFinished = ref(false);

const handleInput = (setIndex, nodeIndex, event) => {
  nodeSets.value[setIndex][nodeIndex] = event.target.textContent.trim();
  event.target.innerText = nodeSets.value[setIndex][nodeIndex];
};

const getNodeStyle = (setIndex, nodeIndex) => {
  return {
    top: `${40 + setIndex * 60}px`,  // Adjusted to position nodes in each set lower
    left: `${nodeIndex * 100 + 50}px`,
    transform: 'translateY(-50%)'
  }
}

const getNodeClass = () => {
  return {
    node: true
  }
}

const getErrorMessage = (errorType) => {
    if(consecutiveErrors.value == 1){
      return 'Ihre Eingabe ist fehlerhaft. Versuchen Sie es erneut.'
    }
    if(consecutiveErrors.value == 2){
      return 'Sehen Sie sich den Graphen nocheinmal genau an und versuchen Sie es erneut.'
    }
    // different message for different error types
    if(consecutiveErrors.value == 3){
      if(errorType == 'wrongLength'){
        return 'Die Anzahl der Knoten in einem Zyklus muss zwischen 3 und 5 liegen. Sehen Sie sich den Graphen noch einmal genau an und versuchen Sie nachvollziehen wie viele Zyklen im Graphen existieren und wie viele Knoten diese besitzen.'
      }
      if(errorType == 'wrongNodes'){
        return 'In mindestens einem Zyklus befindet sich ein Knoten mit falschen Namen. Sehen Sie sich den Graphen noch einmal genau an.'
      }
      if(errorType == 'tooManyCycles'){
        return 'Sie haben bereits die richtige Anzahl an Zyklen des Graphen gefunden. Sehen Sie sich den Graphen noch einmal genau an.'
      }
      if(errorType == 'missingCycles'){
        return 'Sie haben noch nicht alle Zyklen des Graphen gefunden. Sehen Sie sich den Graphen noch einmal genau an.'
      }
      if(errorType == 'missingNodeName'){
        return 'In mindestens einem Zyklus fehlt ein Knotenname. Sehen Sie sich den Graphen noch einmal genau an.'
      }
    }
    if(consecutiveErrors.value >= 4){
      const nextSolution = "Ihre Lösung ist nicht korrekt. Ein, in Ihrer Lösung fehlender Zyklus lautet: " + getNextSolution().map(value => value.toUpperCase()).join(', ') + ". Versuchen Sie die Lösung nachzuvollziehen indem Sie den entsprechenden Zyklus im Graph genau verfolgen.";
      return nextSolution;
    }
}

const getNextSolution = () => {
  let nextSolution = [];
  const sortedSets = nodeSets.value.map(arr => 
    arr.map(value => value.toLowerCase()).sort()
  );

  for(let i = 0; i < correctSolutions.length; i++) {
    const sortedSolution = correctSolutions[i].map(value => value.toLowerCase()).sort();
    if (!sortedSets.some(set => JSON.stringify(set) === JSON.stringify(sortedSolution))) {
      nextSolution = sortedSolution;
      break;
    }
  }
  return nextSolution;
};

const isCreatedCyclesLength = () => {
  let setsWith4Nodes = 2; //from correct solutions
  let setsWith3Nodes = 3; //from correct solutions
  let setsWith5Nodes = 1; //from correct solutions
  
  // how many sets remain in each length
  nodeSets.value.forEach(set => {
    if(set.length == 4) {
      setsWith4Nodes--;
    }
    if(set.length == 3) {
      setsWith3Nodes--;
    }
    if(set.length == 5) {
      setsWith5Nodes--;
    }
  })

  // length of my new set
  switch(nodeCountInput.value){
    case 4:
      setsWith4Nodes--;
      break;
    case 3:
      setsWith3Nodes--;
      break;
    case 5:
      setsWith5Nodes--;
      break;
    default:
      throw new Error("ERROR: No valid action given.");
  }

  // check if there are too many sets
  if(setsWith4Nodes < 0 || setsWith3Nodes < 0 || setsWith5Nodes < 0){
    return false;
  }
  return true;
}

const isNewSetValid = () => {
  if(nodeCountInput.value < 3 || nodeCountInput.value > 5) {
    consecutiveErrors.value++;
    totalWrongLengthErrors.value++;
    resultMessage.value = getErrorMessage('wrongLength');
    return false;
  }
  if(!isCreatedCyclesLength()){
    consecutiveErrors.value++;
    totalWrongLengthErrors.value++;
    resultMessage.value = getErrorMessage('wrongLength');
    return false;
  }
  if(nodeSets.value.length > correctSolutions.length){
    consecutiveErrors.value++;
    totalTooManyCyclesErrors.value++;
    resultMessage.value = getErrorMessage('tooManyCycles');
    return false;
  }
  consecutiveErrors.value = 0;
  resultMessage.value = '';
  return true;
}

const handlePlusClick = () => {
  if (isNewSetValid()) {
    const arr = Array.from(' '.repeat(nodeCountInput.value))
    nodeSets.value.push(arr)
  }
}

const areAllSetsComplete = () => {
  let setComplete = true;
  nodeSets.value.forEach(set => {
    for(let i = 0; i < set.length; i++) {
      if(!set[i] || set[i]=='' || set[i] == ' ') {
        setComplete = false;
        break;
      }
    }
  });
  return setComplete;
}

const areAllSetsValid = () => {
  let solutionsValid = true;
  const sortedSets = nodeSets.value.map(arr => 
    arr.map(value => value.toLowerCase()).sort()
  );

  correctSolutions.forEach(solution => {
    const sortedSolution = solution.map(value => value.toLowerCase()).sort();
    if (!sortedSets.some(set => JSON.stringify(set) === JSON.stringify(sortedSolution))) {
      solutionsValid = false;
    }
  });

  return solutionsValid;
};

const isSubmittedSolutionValid = () => {
  //check if all 6 solutions were found
  if(nodeSets.value.length < 6) {
    console.log('not enough sets');
    consecutiveErrors.value++;
    totalMissingCyclesErrors.value++;
    resultMessage.value = getErrorMessage('missingCycles');
    return false;
  }
  //check if all 6 solutions are filled
  if(!areAllSetsComplete()) {
    console.log('not all sets are filled');
    consecutiveErrors.value++;
    totalMissingNodeNameErrors.value++;
    resultMessage.value = getErrorMessage('missingNodeName');
    return false;
  }
  //check if all 6 solutions match the correct solution
  if(!areAllSetsValid()) {
    console.log('not all sets are valid');
    consecutiveErrors.value++;
    totalWrongNodesErrors.value++;
    resultMessage.value = getErrorMessage('wrongNodes');
    return false;
  }
  consecutiveErrors.value = 0;
  resultMessage.value = '';
  return true;
}

const handleSubmit = () => {
  if(isSubmittedSolutionValid()) {
    assignmentFinished.value = true;  
    const myErrors = {
      totalWrongLengthErrors: totalWrongLengthErrors.value,
      totalMissingCyclesErrors: totalMissingCyclesErrors.value,
      totalMissingNodeNameErrors: totalMissingNodeNameErrors.value,
      totalWrongNodesErrors: totalWrongNodesErrors.value,
      totalTooManyCyclesErrors: totalTooManyCyclesErrors.value
    }
    store.commit('graphA2SaveErrors', JSON.parse(JSON.stringify(myErrors)));  
    resultMessage.value = 'Ihre Lösung ist richtig! Sie haben diese Aufgabe erfolgreich abgeschlossen!'
    emit("enableWeiter");
  }
}

const resetForm = () => {
  nodeSets.value = []
  resultMessage.value = ''
}

onMounted(() => {
  emit("disableWeiter");
  nextTick(() => {
    // Any additional logic to run on mount
  })
})
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Aufgabe 2</h1>
      <p>Im zweiten Teil dieses Moduls ist es Ihre Aufgabe, alle Zyklen eines Graphen zu finden.
        Erinnern Sie sich an die Voraussetzungen eines zyklischen Graphen und tragen Sie Ihre Lösungen in die Textfelder ein.
      </p>
   </div>

    <div class="content">
      <div class="image-item">
        <img :src="image1" alt="tutorial2" class="image1" />
      </div>

      <div class="input-container">
        <div class="sql-input">
          <div v-for="(nodeSet, setIndex) in nodeSets" :key="setIndex">
            <div v-for="(node, nodeIndex) in nodeSet" :key="nodeIndex" :class="getNodeClass()" :style="getNodeStyle(setIndex, nodeIndex)" contenteditable="true" @input="handleInput(setIndex, nodeIndex, $event)">
              <div>{{ node }}</div>
            </div>
          </div>
        </div>
        <div class="button-group">
          <div class="add-button-group">
          <label for="node-count">Anzahl Knoten im Zyklus</label>
          <input 
          class="form-control custom-input" id="node-count" type="number" min="1" max="5" v-model="nodeCountInput"/>
          <button type="button" class="plus-button" @click="handlePlusClick" :disabled="nodeSets.length >= 10">+</button>
        </div><div class="submit-button-group">
          <button type="button" class="submit-button" @click="handleSubmit">Lösung überprüfen</button>
          <button type="button" class="repeat-button" @click="resetForm">Zurücksetzen</button>
        </div></div>
      </div>
    </div>

    <div class="additional-box">
      {{ resultMessage }}
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  text-align: left;
}

.row {
  margin-bottom: 20px;
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.image-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  max-width: 95%;
  height: auto;
  margin-top: 50px;
}

.input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sql-input {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
  width: 100%;
  height: 400px;
  position: relative;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-button-group{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.submit-button-group{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.submit-button, .repeat-button, .next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button, .repeat-button {
  background-color: #4CAF50;
  color: white;
}

.submit-button:hover, .repeat-button:hover {
  background-color: #45a049;
}

.plus-button {
  font-size: 2rem;
  width: 3rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #105490;
  color: white;
}

.plus-button:hover{
  background-color:  #0d406d;
}

.plus-button:disabled {
  background-color: #e7e7e7; 
  color: #ccc;
  cursor: not-allowed;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
}

.button-active {
  background-color: #4CAF50; 
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7; 
  color: rgb(123, 117, 117);
  cursor: not-allowed; 
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}

.node {
  width: 80px;
  /* increased width for better text visibility */
  height: 50px;
  /* increased height for better text visibility */
  border-radius: 10px;
  /* changed to rectangular shape for better text visibility */
  background-color: #105490;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  /* adjusted font size */
  position: absolute;
  cursor: pointer;
  user-select: text;
}

.node:focus {
  border: 0.2rem solid #ffffff;
  font-size: 20px;
  width: 90px;
  height: 60px;
}
</style>
