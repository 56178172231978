<script setup>
import { ref, onMounted } from "vue";

const cyclicGraphImage = ref(
  require("@/assets/inf/graphs/graphs_cycle_illustration.jpg")
);

const treeImage = ref(
  require("@/assets/inf/graphs/graphs_tree_illustration.jpg")
);
onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Merkmale und Eigenschaften zyklischer Graphen: Eine Einführung</h1>
      <p>
        In einem Graphen sprechen wir von einem Zyklus, wenn ein Pfad existiert,
        der beim gleichen Knoten beginnt und endet, ohne dass Kanten wiederholt
        werden.
      </p>

      <p>
        Ein Beispiel eines Zyklus wäre eine Wanderung, bei der Sie eine Reihe
        von Punkten besuchen wollen. Dabei beginnen und enden Sie dort, wo Sie
        gestartet sind, ohne dass Sie den gleichen Weg mehrmals gegangen sind.
        Bei der Wanderung handelt es sich also um einen Rundweg.
      </p>

      <h2><strong>Merkmale eines zyklischen Graphen:</strong></h2>
      <ol>
        <li>
          Ein zyklischer Graph enthält einen oder mehrere Zyklen oder
          geschlossene Pfade, was bedeutet, dass man einen Teil des oder den
          ganzen Graphen durchlaufen kann und dort endet wo man begonnen
          hat.
        </li>
        <li>
          Ein Graph ohne Zyklen wird <em>azyklisch</em> oder
          <em>zyklenfrei</em> genannt.
        </li>
        <li>
          Ein zusammenhängender, azyklischer Graph wird auch
          <em>Baum</em> genannt. Zusammenhängend bedeudet, dass von allen Knoten
          ein Weg zu jedem anderen Knoten existiert, es also keine Knoten ohne
          Verbindung zu den anderen Knoten gibt.
        </li>
      </ol>
      <div class="row image-and-text-container">
        <div class="col">
          <img :src="cyclicGraphImage" alt="Zyklischer Graph" class="image" />
          <div class="image-text">
            Graph mit einem Zyklus. Die Knoten A, B, E und F bilden diesen
            Zyklus.
          </div>
        </div>

        <div class="col">
          <img :src="treeImage" alt="Baum" class="image" />
          <div class="image-text">
            Nicht zusammenhängender, azyklischer Graph. Die Knoten A, B, E und F
            sind nicht vom Knoten C oder D erreichbar. Durch hinzufügen einer
            Kante (z.B. zwischen B und C) kann dieser Graph zu einem Baum
            gemacht werden.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
li {
  margin: 0rem 0rem 1rem 2rem;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}

.image-text {
  text-align: center;
  font-size: small;
}

.image {
  height: 14rem;
  width: auto;
}
</style>
