<script setup>
import { ref } from "vue";
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});

const image = ref(require("@/assets/inf/graphs/Friends.png"));
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Was ist ein Graph?</h1>
      <p>
        In der Datenwissenschaft werden Graphen verwendet, um Beziehungen
        zwischen Objekten darzustellen und zu analysieren. Stellen Sie sich das
        Ganze als eine Sammlung von Punkten vor, wobei jeder Punkt (der "Knoten"
        oder in englisch als "Vertex" bezeichnet wird) ein Objekt oder einen
        Gegenstand repräsentiert. Linien, die diese Punkte verbinden werden als
        Kanten bezeichnet und veranschaulichen die Beziehungen oder Verbindungen
        zwischen diesen Objekten.
      </p>
      <p>
        Denken Sie an eine Gruppe von Personen, deren Beziehungen untereinander
        als Graph dargestellt werden können. Jede Person in dieser Gruppe ist
        ein Knoten. Wenn zwei Personen befreundet sind, zeichnen wir
        eine Linie (Kante), die die entsprechenden Freunde, also Knoten,
        verbindet. Visuelle Darstellungen einer solchen Freundesgruppe helfen
        uns direkt zu sehen, wer mit wem befreundet ist. Unten sehen Sie ein
        Beispiel einer visuellen Darstellung.
      </p>
    </div>

    <div class="image-and-text-container animate glow delay-4">
      <img :src="image" alt="Friends" class="image" />
      <div class="Image-text">
        Darstellung eines Freundesgruppe als Graph. Anna ist mit Ben und Tom
        befreundet, Tom ist mit Anna und Ben befreundet, und Beri ist mit Ben
        befreundet.
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

#image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul li {
  margin: 0 0 24px;
  list-style-type: none;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.image-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Image-text {
  text-align: center;

  font-size: small;
}
.image {
  height: 500px;
}
</style>
