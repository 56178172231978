<script setup>
import { ref } from "vue";
import { onMounted } from "vue";
import Bar from "@/components/inf/graphs/helpers/Bar.vue";
import Intro1 from "@/components/inf/graphs/Assignment1/Intro1.vue";
import Intro1_2 from "@/components/inf/graphs/Assignment1/Intro1_2.vue";
import Intro1_3 from "@/components/inf/graphs/Assignment1/Intro1_3.vue";
import Tutorial from "@/components/inf/graphs/Assignment1/Tutorial.vue";
import Assignment1_1 from "@/components/inf/graphs/Assignment1/Assignment1_1.vue";
import Assignment1_2 from "@/components/inf/graphs/Assignment1/Assignment1_2.vue";
import Intro2 from "@/components/inf/graphs/Assignment2/Intro2.vue";
import Intro2_1 from "@/components/inf/graphs/Assignment2/Intro2_1.vue";
import Intro2_2 from "@/components/inf/graphs/Assignment2/Intro2_2.vue";
import Intro2_3 from "@/components/inf/graphs/Assignment2/Intro2_3.vue";
import Intro2_4 from "@/components/inf/graphs/Assignment2/Intro2_4.vue";
import Tutorial2 from "@/components/inf/graphs/Assignment2/Tutorial2.vue";
import Assignment2 from "@/components/inf/graphs/Assignment2/Assignment2.vue";
import GraphsAppendix1 from "@/components/inf/graphs/GraphsAppendix1.vue";
import GraphsAppendix2 from "@/components/inf/graphs/GraphsAppendix2.vue";
import GraphsWeigtedGraphs from "@/components/inf/graphs/GraphsWeightedGraphs.vue";
import GraphsPathfinding from "@/components/inf/graphs/GraphsPathfinding.vue";

import * as scrollHelper from "@/helpers/scroll-helper.js";
import router from "@/router";
import store from "@/store";
import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

onMounted(() => {
  scrollHelper.scrollToTop();
});

const currentTab = ref(0);
const tabs = [
  Intro1,
  Intro1_2,
  Intro1_3,
  Tutorial,
  Assignment1_1,
  Assignment1_2,
  Intro2,
  Intro2_1,
  Intro2_2,
  Intro2_3,
  Intro2_4,
  Tutorial2,
  Assignment2,
  GraphsPathfinding,
  GraphsWeigtedGraphs,
  GraphsAppendix1,
  GraphsAppendix2
];
const next = ref(true);

function forwardBtnClicked() {
  if(next.value){
  currentTab.value++;
  }
}
function backBtnClicked() {
  enable();
  currentTab.value--;
}
async function finishBtnClicked() {
  const myOSA = await myOSAhandler.getMyInformatikOSA(
    store.getters.getUser.username
  );
  //set graphFinished=true in backend
  await myOSAhandler.finishModule(myOSA.id, { graphFinished: true });
  //set graph module in backend
  store.dispatch("graphLogPost")
  router.push("/informatik");
}
function disable() {
  next.value = false;
}
function enable() {
  next.value = true;
}
</script>

<template>
  <div class="container">
    <Bar :showHelpBtn="false" :showDismissBtn="true" :showFinishBtn="false" />
    <component
      @disable-weiter="disable"
      @enable-weiter="enable"
      :is="tabs[currentTab]"
    />
    <div
      class="custom-btn"
      v-if="currentTab == tabs.length - 1 ? false : true "
      :class="{ 'custom-btn-disabled': next == false}"
      @click="forwardBtnClicked"
    >
      Weiter
    </div>
    <div
      class="custom-btn"
      @click="finishBtnClicked"
      v-if="currentTab == tabs.length - 1"
    >
      Modul beenden
    </div>
    <div
      class="custom-btn-secondary"
      @click="backBtnClicked"
      v-if="currentTab > 0 ? true : false"
    >
      Zurück
    </div>
  </div>
</template>
<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
</style>
