<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Wegfindung in Graphen</h1>
      <p>
        In der vorigen Aufgabe sollten Sie alle Zyklen eines Graphen finden.
        Dafür haben Sie einen Weg zwischen Knoten gesucht und so versucht einen
        Zyklus zu identifizieren. In der Informatik wird diese Vorgehensweise
        als <em>Wegfindung</em> bezeichnet.
      </p>
      <p>
        <em>
          Wegfindung ist das Ausarbeiten einer Route zwischen zwei Punkten durch
          eine Computeranwendung.</em>
      </p>

      <p>
        In der Graphentheorie wird die Wegfindung verwendet um einen Graphen zu
        untersuchen, indem dessen Kanten nach bestimmten Kriterien ausgewertet werden.
        Hier können unterschiedliche Ziele verfolgt werden. So kann zum Beispiel der kürzeste Weg
        zwischen zwei Knoten gesucht werden. Es ist aber auch möglich den längsten, schnellsten, 
        kostengünstigsten, etc. Weg zu finden.
      </p>

      <p>
        Um solche optimalen Wege erstellen zu können müssen die Kanten innerhalb
        eines Graphen "gewichtet" werden. Ihnen muss also ein Wert zugewiesen
        werden.
      </p>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
</style>
