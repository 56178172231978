<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Appendix: Arten von Graphen</h1>
      <p>
        Sie haben bereits einige unterschiedliche Graphen kennengelernt. Doch es
        gibt noch viel mehr Graphen. Hier sind einige davon:
      </p>
      <ul>
        <li>
          <strong>Ungerichtete Graphen: </strong>Diese Graphen haben Kanten ohne
          Richtung. Die Kanten zeigen eine zweiseitige Beziehung an, sodass jede
          Kante in beide Richtungen durchquert werden kann.
        </li>
        <li>
          <strong>Gerichtete Graphen: </strong>In gerichteten Graphen haben alle
          Kanten eine Richtung. Das bedeutet, wenn eine Kante von Knoten A zu
          Knoten B zeigt, kann man nur von A nach B reisen und nicht umgekehrt,
          es sei denn, es gibt eine entsprechende Kante von B nach A.
        </li>
        <li>
          <strong>Gewichtete Graphen: </strong>Diese Graphen haben Kanten, die
          Gewichte tragen, welche Kosten, Entfernungen oder andere Metriken
          darstellen können, die eine zusätzliche Dimension zur Verbindung
          zwischen zwei Knoten hinzufügt.
        </li>
        <li>
          <strong>Ungewichtete Graphen: </strong>In ungewichteten Graphen werden
          alle Kanten als gleich angesehen und haben keine Gewichte.
        </li>
        <li>
          <strong>Zyklische vs. azyklische Graphen: </strong>Zyklische Graphen
          enthalten Zyklen, das sind Pfade von Knoten und Kanten, die am
          gleichen Knoten beginnen und enden. Azyklische Graphen haben solche
          Zyklen nicht. Eine Sonderform eines azyklischen Graphen ist ein
          <em>Baum</em>.
        </li>
        <li>
          <strong>Verbundene vs. unverbundene Graphen: </strong>In einem
          verbundenen Graphen gibt es einen Pfad zwischen jedem Paar von
          Knotenpunkten. Ein unverbundener Graph ist nicht vollständig
          verbunden, was bedeutet, dass einige Knotenpunkte nicht erreicht
          werden können.
        </li>
        <li>
          <strong>Baum: </strong>Eine spezielle Art von azyklischen verbundenen
          Graphen, bei dem jedes Paar von Knotenpunkten genau durch einen Pfad
          verbunden ist.
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
</style>
