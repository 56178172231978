<script setup>
import { onMounted, ref } from "vue";

const image = ref(require("@/assets/inf/graphs/gewichteter_graph.jpg"));

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Gewichtete Graphen</h1>
      <p>
        Ein gewichteter Graph ist ein Graph, bei dem jeder Kante ein Wert
        zugewiesen ist. Diese Werte werden als <em>Kantengewicht</em> bezeichnet
        und können unterschiedliche Attribute, wie zum Beispiel Kosten,
        Entfernungen oder Kapazitäten beschreiben. In Beispiel eines gewichteten Graphen sehen Sie hier:
      </p>

      <div class="image-and-text-container animate glow delay-4">
      <img :src="image" alt="gewichteter Graph" class="image" />
      <div class="Image-text">
        Gewichteter Graph
      </div>
    </div>

      <p>
        Dadurch werden gewichtete Graphen in verschiedenen Szenarien verwendet.
        So werden sie etwa eingesetzt um optimale Transportwege zu berechnen
        oder um elektrische Schaltkreise zu beschreiben.
      </p>

      <h2>Algorithmen zur Wegfindung</h2>

      <p>
        Die Wegfindung in gewichteten Graphen beinhaltet das Finden eines Weges,
        welcher bestimmte Voraussetzungen erfüllt, z.B. dem kürzesten oder
        günstigsten Weg. Die Herausforderungen einer solchen Wegfindung nehmen
        mit der Komplexität des Graphen und der Art der Gewichtungen zu.
      </p>

      <p>
        Der <strong>Dijkstra-Algorithmus</strong> ist einer der bekanntesten
        Algorithmen zur Ermittlung des kürzesten Weges von einem einzelnen
        Ausgangsknotenpunkt zu allen anderen Knotenpunkten in einem gewichteten
        Graphen mit nicht-negativen Gewichten. Er verwendet einen gierigen
        Ansatz und wählt konsequent den Knotenpunkt mit der geringsten bekannten
        Entfernung.
      </p>
      <p>
        Der <strong>Bellman-Ford-Algorithmus</strong> ist eine Weiterentwicklung
        des Dijkstra-Algorithmus, welcher auch Graphen mit negativen
        Kantengewichten verarbeiten kann. Er ist etwas langsamer als Dijkstras
        Algorithmus, aber auch vielseitiger in seiner Anwendung.
      </p>

      <h2>Überlegungen zur Wegfindung</h2>
      <p>
        Graphentheorie ist ein umfangreiches und spannendes Gebiet in der
        Informatik. Es bietet viele Möglichkeiten welche in unterschiedlichen
        Anwendungsgebieten eingesetzt werden. Doch mit zunehmender Komplexität
        steigen auch die Anforderungen an verwendete Algorithmen. So müssen
        beispielsweise folgende Punkte beachtet werden:
      </p>
      <ul>
        <li>
          <strong>Genauigkeit vs. Effizienz</strong>: Während es oft
          entscheidend ist, den genauesten Weg zu finden, ist auch die
          Recheneffizienz wichtig, insbesondere bei groß angelegten Graphen.
          Hier gilt es - je nach Notwendigkeit - ein gutes Verhältnis zwischen
          Genauigkeit und Effizienz zu finden.
        </li>
        <li>
          <strong>Statische vs. dynamische Graphen</strong>: In statischen
          Graphen ändern sich die Kanten und Gewichte nicht über die Zeit,
          während sie sich in dynamischen Graphen ändern können. Daher ist es
          hier notwendig, dass sich auch der Wegfindungsalgorithmus dynamisch
          anpasst.
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 1.3s;
}
.image-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Image-text {
  text-align: center;

  font-size: small;
}
.image {
  height: 500px;
}
</style>
