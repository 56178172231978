<script setup>
import { ref, nextTick, onMounted, defineEmits } from "vue";
  import store from "@/store"
const emit = defineEmits(["enableWeiter", "disableWeiter"]);

const nodes = ref([]);
const newNodeName = ref("");
const selectedNodes = ref([]);
const edges = ref([]);

//const iSolutionCorrect = ref(false);
const resultMessage = ref("");

const errorLog = ref([{}]);
const submittedSolutionsLog = ref([{}]);

const consecutiveNodeErrors = ref(0);
const consecutiveEdgeErrors = ref(0);

const correctEdges = [
  ["A", "B"],
  ["B", "C"],
  ["C", "D"],
  ["D", "E"],
];
const correctNodes = ["A", "B", "C", "D", "E"];

const getErrorMessageNodeError = (errorType) => {
  if (consecutiveNodeErrors.value == 1) {
    return "Dieser Schritt war nicht korrekt. Versuchen Sie es erneut.";
  }
  if (consecutiveNodeErrors.value == 2) {
    return "Der hinzugefügte Knoten ist fehlerhaft. Sehen Sie sich die Adjazenzmatrix noch einmal genau an und versuchen Sie herauszufinden, welche Knoten der Graph besitzt.";
  }
  if (consecutiveNodeErrors.value == 3) {
    if (errorType == "nodeNameEmpty") {
      return "Sie haben versucht einen Knoten ohne Namen zu erstellen. Bitte geben Sie Namen für den Knoten ein.";
    }
    if (errorType == "nodeNameNotInCorrectNodes") {
      return "Sie haben versucht einen Knoten mit einem Namen, der nicht in der Adjazenzmatrix enthalten ist, zu erstellen. Bitte versuchen Sie es erneut.";
    }
    if (errorType == "nodeNameAlreadyInNodes") {
      return "Dieser Name ist bereits vergeben. Stellen Sie sicher, dass der Name eindeutig ist.";
    }
    if (errorType == "tooManyNodesAdded") {
      return "Sie haben bereits alle Knoten, die in der Adjazenzmatrix angegeben sind, hinzugefügt.";
    }
  }
  if (consecutiveNodeErrors.value == 4) {
    return "Der Graph besitzt folgende Knoten: A, B, C, D, E. Diese sind in der Adjazenzmatrix als Überschriften der Spalten und Zeilen angegeben.";
  }
};

const handlePlusClick = () => {
  if (newNodeName.value.trim() == "") {
    // newNode is empty
    errorLog.value.push({
      errorType: "nodeNameEmpty",
    });

    consecutiveNodeErrors.value += 1;
    resultMessage.value = getErrorMessageNodeError("nodeNameEmpty");
    return;
  }

  if (!correctNodes.includes(newNodeName.value.trim())) {
    // newNode is not in the correctNodes array
    errorLog.value.push({
      errorType: "nodeNameNotInCorrectNodes",
    });

    consecutiveNodeErrors.value += 1;
    resultMessage.value = getErrorMessageNodeError("nodeNameNotInCorrectNodes");
    return;
  }

  if (nodes.value.length >= correctNodes.length) {
    // All nodes have been added
    errorLog.value.push({
      errorType: "tooManyNodesAdded",
    });

    consecutiveNodeErrors.value += 1;
    resultMessage.value = getErrorMessageNodeError("tooManyNodesAdded");
    return;
  }

  if (nodes.value.includes(newNodeName.value.trim())) {
    // newNode is already in the nodes array
    errorLog.value.push({
      errorType: "nodeNameAlreadyInNodes",
    });

    consecutiveNodeErrors.value += 1;
    resultMessage.value = getErrorMessageNodeError("nodeNameAlreadyInNodes");
    return;
  }

  // Add the new node to the nodes array
  nodes.value.push(newNodeName.value.trim());
  consecutiveNodeErrors.value = 0; // Reset consecutive node errors
  // Clear the input field
  newNodeName.value = "";
  resultMessage.value = ""; // Clear any previous result message

  if (consecutiveNodeErrors.value >= 4) {
    let nextNodeName = getNextMissingNodeName();
    resultMessage.value = `Der Name des Knotens ist: ${nextNodeName}.`;
  }
};

const getNextMissingNodeName = () => {
  let missingNodeIndex = -1;
  for (let i = 0; i < nodes.value.length; i++) {
    if (!correctNodes.value.includes(nodes.value[i])) {
      missingNodeIndex = i;
      break;
    }
  }
  return correctNodes.value[missingNodeIndex];
};

const getErrorMessageEdgeError = (errorType) => {
  if (consecutiveEdgeErrors.value == 1) {
    return "Ihre Kante ist fehlerhaft. Bitte versuchen Sie es erneut.";
  } else if (consecutiveEdgeErrors.value == 2) {
    return "Die Kante die Sie erstellen möchten ist fehlerhaft. Sehen Sie sich die Adjazenzmatrix noch einmal genau an und versuchen Sie herauszufinden, welche Kanten der Graph besitzt.";
  } else if (consecutiveEdgeErrors.value == 3) {
    if (errorType == "sameNodeSelectedTwice") {
      if (consecutiveEdgeErrors.value == 3) {
        return "Sie haben versucht eine Kante mit demselben Knoten zu erstellen. Bitte versuchen Sie es erneut.";
      }
    }
    if (errorType == "edgeAlreadyExists") {
      return "Diese Kante existiert bereits.";
    }
    if (errorType == "tooManyEdgesAdded") {
      return "Sie haben bereits alle Kanten hinzugefügt.";
    }
    if (errorType == "wrongStartEndEdge") {
      return "Falsche Start- oder Endknoten. Bitte versuchen Sie es erneut.";
    }
  }
  // default four consecutive edge errors
  if(edges.value.length >= correctEdges.length) {
    return "Sie haben bereits alle Kanten der Adjazenzmatrix erstellt. Sie können die Lösung mit dem Button 'Lösung überprüfen' abgeben.";
  }
  const nextMissingEdge = getNextMissingEdge();
  return `Die Kante die Sie erstellen wollen ist nicht korrekt. Erstellen Sie die fehlende Kante (${ nextMissingEdge[2] }, ${ nextMissingEdge[6] }) und versuchen Sie nachzuvollziehen warum Ihre Lösung fehlerhaft ist. Sehen Sie sich dazu die Zahlen in der Adjazenzmatrix genau an.`;
};

const getNextMissingEdge = () => {
  const correctEdgeSet = new Set(
    correctEdges.map((edge) => JSON.stringify(edge))
  );

  edges.value.forEach((edge) => {
    edge.sort();
  });
  const edgeSet = new Set(edges.value.map((edge) => JSON.stringify(edge)));

  let missingEdges = [];
  for (const edge of correctEdgeSet) {
  if (!edgeSet.has(edge)) {
    missingEdges.push(edge);
  }
}
  return missingEdges[0];
};

const handleNodeClick = (index) => {
  if (selectedNodes.value.includes(index)) {
    // click on already selected node --> remove it from selectedNodes
    consecutiveEdgeErrors.value++;
    resultMessage.value = getErrorMessageEdgeError("sameNodeSelectedTwice");
    errorLog.value.push({
      errorType: "sameNodeSelectedTwice",
    });
    selectedNodes.value = selectedNodes.value.filter((node) => node !== index);
    return;
  }

  selectedNodes.value.push(index);

  if (selectedNodes.value.length == 2) {
    const node1 = nodes.value[selectedNodes.value[0]];
    const node2 = nodes.value[selectedNodes.value[1]];

    // check if edge is already drawn
    let edgeAlreadyExistsError = false;
    edges.value.forEach((edge) => {
      if (
        (edge[0] == node1 && edge[1] == node2) ||
        (edge[1] == node1 && edge[0] == node2)
      ) {
        edgeAlreadyExistsError = true;
      }
    });

    if (edgeAlreadyExistsError) {
    errorLog.value.push({
      errorType: "edgeAlreadyExists",
    });
      consecutiveEdgeErrors.value++;
      resultMessage.value = getErrorMessageEdgeError("edgeAlreadyExists");
      selectedNodes.value = [];
      return;
    }

    // check if start and end are correct
    let edgeExistsInCorrectEdges = false;
    correctEdges.forEach((edge) => {
      if (
        (edge[0] == node1 && edge[1] == node2) ||
        (edge[0] == node2 && edge[1] == node1)
      ) {
        edgeExistsInCorrectEdges = true;
      }
    });

    if (!edgeExistsInCorrectEdges) {
    errorLog.value.push({
      errorType: "wrongStartEndEdge",
    });
      consecutiveEdgeErrors.value++;
      resultMessage.value = getErrorMessageEdgeError("wrongStartEndEdge");
      selectedNodes.value = [];
      return;
    }

    consecutiveEdgeErrors.value = 0;
    resultMessage.value = "";
    edges.value.push([node1, node2]);
    selectedNodes.value = [];
  }
};

const getNodeStyle = (index) => {
  switch (index) {
    case 0:
      return { top: "20px", left: "50%", transform: "translateX(-50%)" }; // Center top for first node
    case 1:
      return { top: "100px", left: "calc(50% - 80px)" }; // Below first node, to the left for second node
    case 2:
      return { top: "100px", left: "calc(50% + 30px)" }; // Below first node, to the right for third node
    case 3:
      return { top: "200px", left: "calc(50% - 80px)" }; // Below second node, to the left for fourth node
    case 4:
      return { top: "200px", left: "calc(50% + 30px)" }; // Below third node, to the right for fifth node
    default:
      return {}; // Default case if more than 5 nodes somehow
  }
};

const getNodeClass = (node, index) => {
  return [
    "node",
    "node-" + node,
    selectedNodes.value.includes(index) ? "node-selected" : "",
  ];
};

const calculateEdgePosition = (node1, node2) => {
  const container = document.querySelector(".graph-input");
  const node1El = container.querySelectorAll(".node-" + node1)[0];
  const node2El = container.querySelectorAll(".node-" + node2)[0];

  if (!node1El || !node2El) return {};

  const rect1 = node1El.getBoundingClientRect();
  const rect2 = node2El.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  // Coordinates of node centers
  const x1 = rect1.left + rect1.width / 2 - containerRect.left;
  const y1 = rect1.top + rect1.height / 2 - containerRect.top;
  const x2 = rect2.left + rect2.width / 2 - containerRect.left;
  const y2 = rect2.top + rect2.height / 2 - containerRect.top;

  // Calculate distance and angle
  const dx = x2 - x1;
  const dy = y2 - y1;
  const angle = Math.atan2(dy, dx);

  // Node radius and edge buffer
  const nodeRadius = 25; // Half of the node diameter, nodes are 50px in diameter
  const edgeOffset = 5; // How much shorter each end of the line should be

  // Calculate start and end points to not overlap nodes
  const startOffset = nodeRadius + edgeOffset;
  const endOffset = nodeRadius + edgeOffset;
  const length = Math.sqrt(dx * dx + dy * dy) - startOffset - endOffset;

  // Adjusted start position
  const startX = x1 + startOffset * Math.cos(angle);
  const startY = y1 + startOffset * Math.sin(angle);

  return {
    width: `${length}px`,
    height: "2px", // Keeping your edge thickness
    top: `${startY}px`,
    left: `${startX}px`,
    transform: `rotate(${angle * (180 / Math.PI)}deg)`,
    transformOrigin: "0 0",
    position: "absolute",
    backgroundColor: "black", // Ensure the line color is set here or in CSS
  };
};

const handleSubmit = () => {
  
  submittedSolutionsLog.value.push({
      nodes: nodes.value,
      edges: edges.value,
    });

  const correctEdgeSet = new Set(
    correctEdges.map((edge) => JSON.stringify(edge))
  );

  edges.value.forEach((edge) => {
    edge.sort();
  });
  const edgeSet = new Set(edges.value.map((edge) => JSON.stringify(edge)));

  let areEdgesCorrect =
    [...correctEdgeSet].every((edge) => edgeSet.has(edge)) &&
    edgeSet.size === correctEdgeSet.size;

  if (areEdgesCorrect) {
    resultMessage.value =
      "Ihre Lösung ist korrekt. Sie können nun mit der zweiten Aufgabe fortfahren.";
    consecutiveSolutionErrors.value = 0;
    store.commit('graphA11SaveSolutionLog', JSON.parse(JSON.stringify(submittedSolutionsLog.value)));
    store.commit('graphA11SaveErrorLog', JSON.parse(JSON.stringify(errorLog.value)));
    emit("enableWeiter");
  } else {
    consecutiveSolutionErrors.value += 1;
    if (consecutiveSolutionErrors.value == 1) {
      resultMessage.value =
        "Ihre Lösung ist nicht korrekt. Bitte versuchen Sie es erneut.";
    } else if (consecutiveSolutionErrors.value == 2) {
      resultMessage.value =
        "Ihr Graph enthält falsche Elemente. Überprüfen Sie die Adjazenzmatrix noch einmal und versuchen Sie es erneut. ";
    } else if (consecutiveSolutionErrors.value == 3) {
      if (areNodesMissing()) {
        resultMessage.value =
          "Ihr Graph enthält nicht alle Knoten. Erstellen Sie alle Knoten, die in der Adjazenzmatrix angegeben sind.";
      } else if (areEdgesMissing()) {
        resultMessage.value =
          "Ihr Graph enthält nicht alle Kanten. Erstellen Sie alle Kanten, die in der Adjazenzmatrix angegeben sind.";
      }
    } else {
      resultMessage.value =
        "Die richtige Lösung lautet: Sie müssen 5 Knoten erstellen: A, B, C, D, E. Knoten A ist mit Knoten B, Knoten B ist mit Knoten C, Knoten C ist mit Knoten D, und Knoten D ist mit Knoten E verbunden.";
    }
  }
};

const consecutiveSolutionErrors = ref(0);

const areNodesMissing = () => {
  return correctNodes.some((node) => !nodes.value.includes(node));
};

const areEdgesMissing = () => {
  return correctEdges.some((edge) => !edges.value.includes(edge));
};

const resetForm = () => {
  nodes.value = [];
  edges.value = [];
  selectedNodes.value = [];
  resultMessage.value = "";
  consecutiveSolutionErrors.value = 0;
  consecutiveEdgeErrors.value = 0;
  consecutiveNodeErrors.value = 0;
};

onMounted(() => {
  emit("disableWeiter")
  nextTick(() => {
    edges.value = edges.value.map((edge) =>
      calculateEdgePosition(edge[0], edge[1])
    );
  });
});
</script>

<template>
  <div class="container">
    <div class="row animate glow delay-1">
      <h1>Aufgabe 1.1</h1>
      <p>Erstellen Sie einen Graphen gemäß der untenstehenden Matrix.</p>
      <p>
        Klicken Sie auf das <strong>Pluszeichen</strong>, nachdem Sie den
        <strong>Namen</strong> eingegeben haben, um einen
        <strong>Knoten</strong> zu erstellen. Um zwei Knoten mit einer
        <strong>Kante</strong> zu verbinden,
        <strong>klicken Sie nacheinander auf die zu verbindenen Knoten</strong>.
      </p>
      <p>
        Sollten Sie fertig sein, können Sie Ihre Lösung mit der Schaltfläche
        <strong>"Lösung überprüfen"</strong> kontrollieren.
      </p>
    </div>

    <div class="content">
      <div class="image-item">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>A</th>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <th>B</th>
              <td>1</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <th>C</th>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
            </tr>
            <tr>
              <th>D</th>
              <td>0</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
              <td>1</td>
            </tr>
            <tr>
              <th>E</th>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>1</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="input-container">
        <div class="graph-input">
          <div
            v-for="(node, index) in nodes"
            :key="index"
            :class="getNodeClass(node, index)"
            :style="getNodeStyle(index)"
            @click="handleNodeClick(index)"
          >
            {{ node }}
          </div>
          <div
            v-for="(edge, index) in edges"
            :key="'edge-' + index"
            class="edge"
            :style="calculateEdgePosition(edge[0], edge[1])"
          ></div>
        </div>
        <input v-model="newNodeName" placeholder="Name des Knotens" />
        <div class="button-group">
          <button type="button" class="plus-button" @click="handlePlusClick">
            +
          </button>
          <button
            v-if="true"
            type="button"
            class="submit-button"
            @click="handleSubmit"
          >
            Lösung überprüfen
          </button>
          <button type="button" class="repeat-button" @click="resetForm">
            Graph zurücksetzen
          </button>
        </div>
      </div>
    </div>

    <div class="additional-box">
      {{ resultMessage }}
    </div>
  </div>
</template>

<style scoped>
p {
  line-height: 1.3;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  text-align: left;
}

.row {
  margin-bottom: 20px;
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.image-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  max-width: 100%;
  height: 250px;
  margin-top: 50px;
}

.input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.graph-input {
  font-family: "Courier New", Courier, monospace;
  margin-bottom: 10px;
  padding: 20px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  transition: background-color 0.3s;
  width: 100%;
  height: 400px;
  position: relative;
}

.correct {
  background-color: lightgreen;
}

.incorrect {
  background-color: lightcoral;
  color: white;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.submit-button,
.repeat-button,
.next-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-button,
.repeat-button {
  background-color: #4caf50;
  color: white;
}

.submit-button:hover,
.repeat-button:hover {
  background-color: #45a049;
}

.plus-button {
  font-size: 3rem;
  width: 3rem;
  height: 3rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #105490;
  color: white;
}

.plus-button:hover {
  background-color: #0d406d;
}

.next-button {
  background-color: #777777;
  color: rgb(74, 73, 73);
}

.button-active {
  background-color: #4caf50;
  color: white;
}

.next-button:disabled {
  background-color: #e7e7e7;
  color: rgb(123, 117, 117);
  cursor: not-allowed;
}

.additional-box {
  width: 100%;
  margin: 10px auto;
  padding: 1px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}

.node {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #105490;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
}

.node-selected {
  background-color: #ffa500; /* Highlight color */
}

.edge {
  position: absolute;
  height: 2px;
  background-color: black;
}

input[type="text"] {
  margin-top: 10px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 12px); /* Adjust width to fit within the container */
}

table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  min-width: 400px;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f2f2f2;
}
</style>
